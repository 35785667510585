<template>
  <a-row type="flex">
    <a-col class="order-history-sidebar" :flex="1">
      <div class="order-content">
        <div class="order-filter-parent">
          <div class="order-filter">
            <a-row>
              <a-range-picker
                @change="onRangeChange"
                :default-value="[rangeDate.start, rangeDate.end]"
              />
            </a-row>
            <a-radio-group
              class="order-filter-select"
              v-model="selectedView"
              @change="onChangeOrderType"
            >
              <a-radio-button value="1">Orden</a-radio-button>
              <a-radio-button value="2">Xpress/Auto</a-radio-button>
            </a-radio-group>
            <div class="history-total-order" v-if="loaded">
              <span>{{ orderTotal }} órdenes encontradas</span>
            </div>
            <div class="history-total-order" v-else>
              <span>Buscando</span>
            </div>
          </div>
        </div>
        <div class="order-list-container">
          <div
            :class="hasRetentions ? 'order-list has-retentions' : 'order-list'"
          >
            <div
              @click="openOrder(order, index)"
              v-for="(order, index) in data.orders"
              :key="index"
              :class="
                activeIndex === index ? 'history-order active' : 'history-order'
              "
            >
              <div class="order-element">
                <a-row type="flex" class="history-order-about history-type">
                  <a-col v-if="getXpress(order)"
                    ><div v-if="xpressOrderType(order)" class="express">
                      Express
                    </div>
                    <div v-else class="auto">Auto</div>
                  </a-col>
                </a-row>
                <a-row type="flex" class="history-order-about">
                  <a-col :flex="3"
                    ><strong>#{{ order.id }}</strong></a-col
                  >
                  <a-col class="order-date" :flex="3">{{
                    getOrderDate(order.created_at)
                  }}</a-col>
                </a-row>
                <a-row type="flex" class="history-order-price">
                  <a-col :span="10">
                    ${{ getFormat(order.subtotal) }}
                    {{
                      !getXpress(order)
                        ? `($${getFormat(order.grand_total)})`
                        : ""
                    }}
                  </a-col>
                  <a-col :class="getOrderClass(order.status)" :span="14">
                    {{ getStatus(order.status) }}
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loaded && orderInRange" class="history-order-resume-totals">
        <div class="content">
          <template v-if="selectedView == 1">
            <a-row type="flex">
              <a-col class="resume-name" :flex="3"> Subtotal </a-col>
              <a-col class="order-total" :flex="3">
                {{ getFormat(data.totals.subtotal) }}
              </a-col>
            </a-row>
            <a-row type="flex">
              <a-col class="resume-name" :flex="3"> - Comisión (15%) </a-col>
              <a-col class="order-total" :flex="3">
                {{ getFormat(data.totals.comission) }}
              </a-col>
            </a-row>
            <a-row type="flex">
              <a-col class="resume-name" :flex="3"> - IVA (16%) </a-col>
              <a-col class="order-total" :flex="3">
                {{ getFormat(data.totals.comissionPct) }}
              </a-col>
            </a-row>
            <template v-if="hasRetentions">
              <a-row type="flex">
                <a-col class="resume-name" :flex="3"> - ISR P.D. </a-col>
                <a-col class="order-total" :flex="3">
                  {{ getFormat(data.totals.isr) }}
                </a-col>
              </a-row>
              <a-row type="flex">
                <a-col class="resume-name" :flex="3"> - IVA P.D. </a-col>
                <a-col class="order-total" :flex="3">
                  {{ getFormat(data.totals.iva_pd) }}
                </a-col>
              </a-row>
            </template>
          </template>
          <a-row type="flex">
            <a-col class="resume-name" :flex="3"> Total </a-col>
            <a-col class="order-total total-history" :flex="3">
              {{ getFormat(data.totals.total) }}
            </a-col>
          </a-row>
        </div>
      </div>
    </a-col>

    <a-col class="order-content-details" :flex="21">
      <div class="order-container">
        <div v-if="selectedOrder" class="history-order-details">
          <a-row type="flex">
            <a-col class="history-order-id">
              Orden #{{ selectedOrder.id }}
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col class="history-order-created">{{ orderDate }}</a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col class="history-order-client" :span="11">
              <div class="details">
                <a-row class="details-name">Cliente</a-row>
                <a-row class="details-data">{{ clientName }}</a-row>
              </div>
            </a-col>
            <a-col
              class="history-order-client history-order-delivery"
              :span="12"
            >
              <div class="details">
                <a-row class="details-name">Repartidor</a-row>
                <a-row class="details-data">{{ deliveryName }}</a-row>
              </div>
            </a-col>
          </a-row>
          <Extras
            :isXpressPriceQr="
              getXpress(this.selectedOrder) &&
              xpressOrderType(this.selectedOrder)
            "
            :orderTickets="orderTickets"
            :key="selectedOrder.id"
          />
          <a-divider type="horizontal" />
          <div class="history-order-totalsub">
            <template v-if="!getXpress(this.selectedOrder)">
              <a-row type="flex">
                <a-col :flex="3">Subtotal</a-col>
                <a-col class="total" :flex="3"
                  >${{ getFormat(selectedOrder.subtotal) }}</a-col
                >
              </a-row>
              <a-row type="flex">
                <a-col :flex="3"> - Comisión (15%)</a-col>
                <a-col class="total" :flex="3">
                  - ${{ getFormat(selectedOrder.comissionPct) }}</a-col
                >
              </a-row>
              <a-row type="flex">
                <a-col :flex="3"> - IVA (16%)</a-col>
                <a-col class="total" :flex="3">
                  - ${{ getFormat(selectedOrder.comission) }}</a-col
                >
              </a-row>
              <a-row type="flex">
                <a-col :flex="3">Total</a-col>
                <a-col class="total" :flex="3"
                  >${{ getFormat(selectedOrder.grand_total) }}</a-col
                >
              </a-row>
            </template>
            <template v-else>
              <template v-if="hasEstablishmentShippingCost">
                <a-row type="flex">
                  <a-col :flex="3">Subtotal</a-col>
                  <a-col class="total" :flex="3"
                    >${{ getFormat(selectedOrder.subtotal) }}</a-col
                  >
                </a-row>
                <a-row type="flex">
                  <a-col :flex="3"> - Envío absorbido</a-col>
                  <a-col class="total" :flex="3"
                    >- ${{
                      getFormat(selectedOrder.establishment_shipping_cost)
                    }}</a-col
                  >
                </a-row>
              </template>
              <a-row type="flex">
                <a-col :flex="3">Total</a-col>
                <a-col class="total" :flex="3"
                  >${{ getFormat(orderTotalAmount) }}</a-col
                >
              </a-row>
            </template>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import moment from "moment";
import orderStatus from "@/utils/orderStatus.js";
import order from "@/utils/xpressStatus.js";
import Extras from "@/components/orders/Extras";
import format from "@/utils/formatNumbers.js";

export default {
  name: "OrderHistory",
  components: {
    Extras,
  },
  data() {
    return {
      selectedView: "1",
      data: Object,
      selectedOrder: null,
      loaded: false,
      rangeDate: {
        start: moment(),
        end: moment(),
        format: "YYYY-MM-DD",
      },
      activeIndex: undefined,
    };
  },
  created() {
    moment.locale("es");
    this.loadOrderHistory();
  },
  methods: {
    getExtraPrice(extra) {
      return extra.cost ? `$${extra.cost}` : "--";
    },
    getFormat(value) {
      return format.getFormatPayment(value);
    },
    getXpress(order) {
      return typeof order.type !== "undefined";
    },
    xpressOrderType(orderReceived) {
      return orderReceived.type === order.type.XPRESS;
    },
    openOrder(order, index) {
      this.selectedOrder = order;
      this.activeIndex = index;
    },
    groupBy(array, key) {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    },
    onRangeChange(date, dateString) {
      this.rangeDate.start = dateString[0];
      this.rangeDate.end = dateString[1];
      this.loadOrderHistory();
    },
    onChangeOrderType() {
      this.selectedOrder = null;
      this.loadOrderHistory();
    },
    async loadOrderHistory() {
      this.loaded = false;
      try {
        this.data = await this.$store.dispatch("get", {
          location: [
            "order-xpress-history-by-establishment",
            this.$store.getters.establishmentId,
            moment(this.rangeDate.start).format(this.rangeDate.format),
            moment(this.rangeDate.end).format(this.rangeDate.format),
            this.selectedView,
          ].join("/"),
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loaded = true;
      }
    },
    getStatus(status) {
      return orderStatus.orderStatus[status];
    },
    getOrderDate(date) {
      return moment(date).format("LLL");
    },
    getOrderClass(status) {
      return orderStatus.orderStatusClass[status];
    },
  },
  computed: {
    hasRetentions() {
      if (this.data.totals) {
        return this.data.totals.has_retentions;
      }
      return false;
    },
    isXpress() {
      return typeof this.selectedOrder.type !== "undefined";
    },
    orderInRange() {
      return this.data.orders.length > 0 ? true : false;
    },
    orderTotal() {
      return this.data.orders.length;
    },
    orderDate() {
      return this.getOrderDate(this.selectedOrder.created_at);
    },
    clientName() {
      if (this.isXpress) {
        return [
          this.selectedOrder.xpress_client.first_name,
          this.selectedOrder.xpress_client.last_name,
        ].join(" ");
      }
      return [
        this.selectedOrder.person.first_name,
        this.selectedOrder.person.last_name,
      ].join(" ");
    },
    deliveryName() {
      return this.selectedOrder.delivery
        ? this.selectedOrder.delivery.person.first_name
        : "";
    },
    orderTotalAmount() {
      return (
        +this.selectedOrder.total -
        +this.selectedOrder.establishment_shipping_cost
      );
    },
    hasEstablishmentShippingCost() {
      return (
        this.isXpress && this.selectedOrder.establishment_shipping_cost > 0
      );
    },
    orderTickets() {
      if (this.isXpress) {
        return this.selectedOrder.xpress_tickets
          ? this.selectedOrder.xpress_tickets
          : null;
      }
      return this.selectedOrder.tickets ? this.selectedOrder.tickets : null;
    },
  },
};
</script>
