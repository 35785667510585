const formatNumbers = {
  getExtraCost(extra, isXpress) {
    if (isXpress && extra.local_pricing !== null) {
      return extra.local_pricing;
    }
    return extra.cost;
  },
};

const productStatus = {
  hasExtras(product) {
    return product.options.length > 0;
  },
  isProductWithPrice(product) {
    return product.qr_pricing !== null;
  },
  getPricing(product) {
    if (product.qr_pricing !== null) {
      return product.qr_pricing;
    }
    return product.selectedQty || product.options.selectedQty;
  },
}

module.exports = { formatNumbers, productStatus };
