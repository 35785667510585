/* const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "MXN",
}); */
const formatNumbers = {
  getFormatPayment(value) {
    let output = parseFloat(value).toFixed(2);
    return `${output}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
};

module.exports = formatNumbers;
